import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import QuillType from 'quill';
import * as Delta from 'quill-delta';

@Component({
  selector: 'wellro-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent {
  @ViewChild('editor')
  private editor: QuillEditorComponent;

  @Input()
  required: boolean;

  @Input()
  placeholder: string;

  @Input()
  styles: any;

  @Input()
  readOnly: boolean;

  @Input()
  content: Delta;

  @Output()
  contentChange = new EventEmitter<EditorContentChange>();

  private data: EditorContentChange;

  modules: QuillModules = {
    formula: false,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  onContentChanged(event: EditorContentChange) {
    this.data = event;
    this.contentChange.emit(event);
  }

  getData() {
    return this.data;
  }
}

export interface EditorContentChange {
  content: Delta;
  delta: Delta;
  editor: QuillType;
  html: string | null;
  oldDelta: Delta;
  source: string;
  text: string;
}
