import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../services/tokens';
import { LoggerService } from '../services/logger.service';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(
    private logger: LoggerService,
    @Inject(BASE_URL)
    private baseUrl: string
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith('/api')) {
      const url = request.url.replace('/api', this.baseUrl);
      this.logger.debug('Request URL reformed', request.url, url);

      const reformedRequest = request.clone({ url });
      return next.handle(reformedRequest);
    }
    return next.handle(request);
  }
}
