import { Component, Input } from '@angular/core';

@Component({
  selector: 'wellro-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
})
export class SkeletonLoaderComponent {
  @Input() count = 1;
  @Input() appearance: 'circle' | '' = '';
  @Input() height = '15px';
  @Input() width = '100%';
  @Input() radius = '';
  @Input() backgroundColor = '';
  @Input() margin = '';

  get theme() {
    return {
      height: this.height,
      'border-radius': this.radius,
      'background-color': this.backgroundColor,
      width: this.width,
      margin: this.margin,
    };
  }
}
