import { Injectable } from '@angular/core';

@Injectable()
export class CurrencyService {
  parseCurrencyInput(input: string) {
    // input should be of the format XXX.YY
    // TODO: Validate currency RegExp
    const regexp = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
    if (!regexp.test(input)) {
      throw new Error('Invalid currency input!');
    }

    const [top, cents] = input.split('.');
    const paddedCents = `${cents || ''}00`.substr(0, 2);

    return parseInt(`${top}${paddedCents}`, 10);
  }

  transformToCurrencyInput(value: number) {
    const stringValue = value.toString();
    const top = stringValue.slice(0, stringValue.length - 2);
    const cents = stringValue.slice(stringValue.length - 2);
    return `${top || '0'}.${cents}`;
  }
}
