import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[wellroLoadingButton]',
})
export class LoadingButtonDirective {
  @Input() isProcessing: boolean;

  @HostBinding('disabled')
  get disabled(): boolean {
    return this.isProcessing;
  }
}
