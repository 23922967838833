import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, TENANT_ID } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AuthDataAccessModule } from '@wellro/auth/data-access';
import { ConfigurationsModule } from '@wellro/configurations';
import { UtilsModule } from '@wellro/utils';
import { environment } from '../../environments/environment';
import { IndexedDbService } from './indexed-db.service';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ConfigurationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    UtilsModule.forRoot({ baseUrl: '/api', logLevel: environment.logLevel }),
    AuthDataAccessModule.forRoot({ authUrl: '/auth' }),
  ],
  providers: [
    IndexedDbService,
    {
      provide: APP_INITIALIZER,
      useFactory: (indexedDBService: IndexedDbService) => () =>
        indexedDBService.initialize(),
      deps: [IndexedDbService],
      multi: true,
    },
    { provide: TENANT_ID, useValue: environment.tenantId },
  ],
})
export class CoreModule {}
