import { Injectable } from '@angular/core';
import { asyncScheduler, scheduled } from 'rxjs';

@Injectable()
export class AuthTokenService {
  getAuthToken() {
    const token = localStorage.getItem('auth_token');
    return scheduled([token], asyncScheduler);
  }

  setAuthToken(token: string) {
    localStorage.setItem('auth_token', token);
    return scheduled([token], asyncScheduler);
  }

  clearAuthToken() {
    const token = localStorage.getItem('auth_token');
    if (token) {
      localStorage.removeItem('auth_token');
    }
  }
}
