<h3 mat-dialog-title class="dialog-title">
  {{ title }}
  <span class="consultation-readable-id" *ngIf="consultationReadableId"
    >#{{ consultationReadableId }}</span
  >
</h3>
<mat-dialog-content class="mat-typography">
  <div class="text">
    {{ message }}
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <ng-container *ngFor="let button of buttons">
    <button
      *ngIf="button.type === 'cancel-button'"
      mat-button
      [mat-dialog-close]="button.value"
      (click)="onButtonClick(button.value)"
    >
      {{ button.text }}
    </button>

    <button
      *ngIf="button.type === 'decline-button'"
      mat-raised-button
      [mat-dialog-close]="button.value"
      color="warn"
      (click)="onButtonClick(button.value)"
    >
      {{ button.text }}
    </button>
  </ng-container>
</mat-dialog-actions>
