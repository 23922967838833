// Refer the following for the state diagram
// https://go.0xait.com/telemedicine-mvp-consultation-states

export enum ConsultationStatus {
  Draft = 10,
  PendingConfirmation = 20,
  DataRejected = 30,
  DataConfirmed = 40,
  PaymentFailed = 50,
  Pending = 60,
  Ongoing = 70,
  AwaitingPatientResponse = 80,
  NewPatientData = 90,
  Completed = 100,

  // closing scenarios
  Closed = 110,
  Cancelled = -10,
  Expired = -20,
  Declined = -30,
  Refunded = -40,
}
