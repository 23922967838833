import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wellro-google-sign-in-button',
  templateUrl: './google-sign-in-button.component.html',
  styleUrls: ['./google-sign-in-button.component.scss'],
})
export class GoogleSignInButtonComponent {
  @Output() btnClick = new EventEmitter<void>();
  @Input() isLoading: boolean;
  @Input() disabled: boolean;
  @Input() label: string;

  onClick(): void {
    this.btnClick.emit();
  }
}
