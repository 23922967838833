import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CLAIMS,
  CLAIM_GROUPS,
  CONSULTATION_STATUSES,
  PROMO_CODE_STATUSES,
  USER_STATUSES,
} from './tokens';

import claims from './configs/claims.json';
import claimGroups from './configs/claim-groups.json';
import userStatuses from './configs/user-statuses.json';
import consultationStatuses from './configs/consultation-statuses.json';
import promoCodeStatuses from './configs/promo-code-statuses.json';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: CLAIMS, useValue: claims },
    { provide: CLAIM_GROUPS, useValue: claimGroups },
    { provide: USER_STATUSES, useValue: userStatuses },
    { provide: CONSULTATION_STATUSES, useValue: consultationStatuses },
    { provide: PROMO_CODE_STATUSES, useValue: promoCodeStatuses },
  ],
})
export class ConfigurationsModule {}
