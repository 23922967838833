import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const bannerAnimation = [
  trigger('bannerSlideToggle', [
    state(
      'opened',
      style({
        'min-height': '62px',
      })
    ),
    state(
      'closed',
      style({
        height: 0,
      })
    ),
    transition('opened => closed', [animate('200ms ease-out')]),
    transition('closed => opened', [animate('200ms ease-in')]),
  ]),
];
