import { Injectable } from '@angular/core';
import { LoggerService } from '@wellro/utils';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export enum STORES {
  PushNotifications = 'push-notifications',
}

@Injectable({
  providedIn: 'root',
})
export class IndexedDbService {
  private readonly DB_VERSION = 1.0;
  private readonly DB_NAME = 'wellro-wellness-db';

  constructor(private loggerService: LoggerService) {
    if (!indexedDB) {
      loggerService.error(`This browser doesn't support IndexedDB`);
    }
  }

  initialize() {
    try {
      const dbConnection = this.getDBConnectionRequest();
      if (!dbConnection) {
        throw new Error('Could not found IndexedDB connection');
      }
      dbConnection.onupgradeneeded = this.onUpgradeNeeded.bind(
        this,
        dbConnection
      );
      this.loggerService.debug('Indexed DB service initiated');
    } catch (error) {
      this.loggerService.error('IndexedDB not initiated', error.message);
    }
  }

  addItem<T = any>(storeName: string, data: T, key?: IDBValidKey) {
    return this.useTransaction(storeName, 'readwrite').pipe(
      switchMap((store) => {
        return new Observable<IDBValidKey>((observer) => {
          const request = store.add(data, key);
          request.onsuccess = (event: any) => {
            observer.next(request.result);
          };
          request.onerror = (event: any) => {
            observer.error(request.error);
          };
        });
      })
    );
  }

  removeItem(storeName: string, key: IDBValidKey | IDBKeyRange) {
    return this.useTransaction(storeName, 'readwrite').pipe(
      switchMap((store) => {
        return new Observable<void>((observer) => {
          const request = store.delete(key);
          request.onsuccess = (event: any) => {
            observer.next();
          };
          request.onerror = (event: any) => {
            observer.error(request.error);
          };
        });
      })
    );
  }

  getItem<T = any>(storeName: string, key: IDBValidKey | IDBKeyRange) {
    return this.useTransaction(storeName, 'readonly').pipe(
      switchMap((store) => {
        return new Observable<T>((observer) => {
          const request = store.get(key);
          request.onsuccess = (event: any) => {
            observer.next(request.result);
          };
          request.onerror = (event: any) => {
            observer.error(request.error);
          };
        });
      })
    );
  }

  updateItem<T = any>(storeName: string, data: T, key?: IDBValidKey) {
    return this.useTransaction(storeName, 'readwrite').pipe(
      switchMap((store) => {
        return new Observable<IDBValidKey>((observer) => {
          const request = store.put(data, key);
          request.onsuccess = (event: any) => {
            observer.next(request.result);
          };
          request.onerror = (event: any) => {
            observer.error(request.error);
          };
        });
      })
    );
  }

  getAllItems<T = any>(storeName: string) {
    return this.useTransaction(storeName, 'readonly').pipe(
      switchMap((store) => {
        return new Observable<T[]>((observer) => {
          const request = store.getAll();
          request.onsuccess = (event: any) => {
            observer.next(request.result);
          };
          request.onerror = (event: any) => {
            observer.error(request.error);
          };
        });
      })
    );
  }

  private useTransaction(storeName: string, mode?: IDBTransactionMode) {
    return this.openDBConnection().pipe(
      map((db) => {
        return db.transaction(storeName, mode);
      }),
      map((transaction) => {
        return transaction.objectStore(storeName);
      })
    );
  }

  private getDBConnectionRequest() {
    const request = indexedDB.open(this.DB_NAME, this.DB_VERSION);
    return request;
  }

  private openDBConnection() {
    return new Observable<IDBDatabase>((observer) => {
      const request = this.getDBConnectionRequest();
      request.onsuccess = (event: any) => {
        observer.next(request.result);
      };
      request.onerror = (event: any) => {
        observer.error(request.error);
      };
    });
  }

  /**
   * Use this for DB upgrade when needed
   * @param dbConnection
   * @param event
   */
  private onUpgradeNeeded(
    dbConnection: IDBOpenDBRequest,
    event: IDBVersionChangeEvent
  ) {
    if (event.newVersion === this.DB_VERSION) {
      const pushNotificationStore = this.createObjectStore(
        dbConnection,
        STORES.PushNotifications,
        { keyPath: 'tag' }
      );
      if (pushNotificationStore) {
        pushNotificationStore.createIndex('tag', 'tag', { unique: true });
      }
    }
  }

  private createObjectStore(
    dbConnection: IDBOpenDBRequest,
    name: STORES,
    options?: IDBObjectStoreParameters
  ) {
    if (!dbConnection.result.objectStoreNames.contains(name)) {
      const store = dbConnection.result.createObjectStore(name, options);
      this.loggerService.debug('IndexedDB store created:', name);
      return store;
    }
    return null;
  }

  private deleteObjectStore(dbConnection: IDBOpenDBRequest, name: string) {
    if (dbConnection.result.objectStoreNames.contains(name)) {
      return dbConnection.result.deleteObjectStore(name);
    }
    return;
  }
}
