import { InjectionToken } from '@angular/core';

export const CLAIMS = new InjectionToken<[string, string, number][]>('CLAIMS');

export const CLAIM_GROUPS = new InjectionToken<
  { name: string; claims: string[] }[]
>('CLAIM_GROUPS');

export const USER_STATUSES = new InjectionToken<string[]>('USER_STATUSES');

export const CONSULTATION_STATUSES = new InjectionToken<string[]>(
  'CONSULTATION_STATUSES'
);

export const PROMO_CODE_STATUSES = new InjectionToken<number[]>(
  'PROMO_CODE_STATUSES'
);
