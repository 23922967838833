import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggerService } from '@wellro/utils';
import { ConfigurationsModule } from '@wellro/configurations';
import { AuthService } from './services/auth.service';
import { Options } from './models/options.model';
import { AUTH_URL, UNAUTHORIZED_URL } from './services/tokens';
import { AuthGuard } from './guards/auth.guard';
import { ClaimGuard } from './guards/claim.guard';
import { HasClaimsDirective } from './directives/has-claims.directive';
import { HasClaimDirective } from './directives/has-claim.directive';

@NgModule({
  imports: [CommonModule, ConfigurationsModule],
  declarations: [HasClaimsDirective, HasClaimDirective],
  exports: [HasClaimsDirective, HasClaimDirective],
})
export class AuthDataAccessModule {
  static forRoot(options: Options) {
    return {
      ngModule: AuthDataAccessModule,
      providers: [
        { provide: AUTH_URL, useValue: options.authUrl },
        { provide: UNAUTHORIZED_URL, useValue: options.unauthorizedUrl },
        AuthService,
        AuthGuard,
        ClaimGuard,
      ],
    };
  }

  constructor(logger: LoggerService) {
    if (!logger) {
      throw new Error(
        'You need to import the UtilsModule before AuthDataAccessModule!'
      );
    }
  }
}
