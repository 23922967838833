import { LogLevel } from '@wellro/utils';

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAgWd7sdHMnm4sSBItDLKw1HvlD8aWkOe4',
    authDomain: 'wellro-wellness-prod.firebaseapp.com',
    projectId: 'wellro-wellness-prod',
    storageBucket: 'wellro-wellness-prod.appspot.com',
    messagingSenderId: '691919511102',
    appId: '1:691919511102:web:819fdc61766ba8c40c3b6f',
    measurementId: 'G-210QNLK42W',
  },
  tenantId: 'doctor-ejtwu',
  logLevel: LogLevel.WARNS,
  hostUrl: 'https://doctor.wellro.life',
};
