import { Inject, Injectable, NgZone } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AUTH_URL } from '../services/tokens';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private ngZone: NgZone,
    private router: Router,
    @Inject(AUTH_URL)
    private authUrl: string
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.auth.getAuthenticationStatusStream().pipe(
      take(1),
      map((authenticated) => {
        if (!authenticated) {
          this.ngZone.run(() => {
            this.router.navigate([this.authUrl]);
          });
        }
        return authenticated;
      })
    );
  }
}
