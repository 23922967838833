<div class="loader" [ngStyle]="{ width: width }">
  <svg class="circular" viewBox="25 25 50 50">
    <circle
      class="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      [ngStyle]="{ stroke: color, 'stroke-width': strokeWidth }"
      stroke-miterlimit="10"
    />
  </svg>
</div>
