import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import 'firebase/app';
import {
  catchError,
  map,
  switchMap,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
import { UserCore, UserRole, UserStatus } from '@wellro/models';
import { AuthTokenService, LoggerService } from '@wellro/utils';
import firebase from 'firebase/app';
import 'firebase/auth';
import { CLAIMS, CLAIM_GROUPS } from '@wellro/configurations';

@Injectable()
export class AuthService {
  constructor(
    private auth: AngularFireAuth,
    private tokenService: AuthTokenService,
    private logger: LoggerService,
    @Inject(CLAIMS)
    private allClaims: [string, string, number][],
    @Inject(CLAIM_GROUPS)
    private allClaimGroups: { name: string; claims: string[] }[]
  ) {
    this.auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        this.tokenService.setAuthToken(token);
        this.logger.info('Auth token updated', token);
      } else {
        this.tokenService.clearAuthToken();
        this.logger.info('Auth token removed');
      }
    });
  }

  getAllClaims() {
    return this.allClaims;
  }

  getAllClaimGroups() {
    return this.allClaimGroups;
  }

  hasClaims(claims: string[], combineWithOr = false) {
    return this.getUserStream().pipe(
      take(1),
      map((user) => {
        if (combineWithOr) {
          return claims.some((claim) => user.claims.includes(claim));
        }
        return claims.every((claim) => user.claims.includes(claim));
      })
    );
  }

  /**
   * Observable stream returning true if user is authenticated, false otherwise
   */
  getAuthenticationStatusStream() {
    return this.auth.user.pipe(map((user) => !!user));
  }

  /**
   * Returns a stream of UserCore, null if user is not logged in
   */
  getUserStream(): Observable<UserCore | null> {
    return this.auth.user.pipe(
      switchMap((user) => {
        if (!user) {
          return of({ result: null, user: null });
        }
        return from(user.getIdTokenResult()).pipe(
          map((result) => ({ result, user }))
        );
      }),
      map(({ result, user }) => {
        if (!result || !user) {
          return null;
        }

        if (!result.claims.claimIds) {
          this.logger.warn(
            'User claim ids array is not as expected',
            result.claims.claims
          );
        }

        if (!result.claims.role) {
          this.logger.warn('User role is not as expected', result.claims.role);
        }

        if (!result.claims.status) {
          this.logger.warn(
            'User status is not as expected',
            result.claims.status
          );
        }

        // map claim ids to the claims
        const claims = this.allClaims
          .filter((claim) =>
            ((result.claims.claimIds as number[]) || []).includes(claim[2])
          )
          .map(([claim]) => claim);

        const userCore: UserCore = {
          userId: user.uid,
          name: user.displayName,
          email: user.email || null,
          mobileNumber: user.phoneNumber || null,
          pictureUrl: user.photoURL,
          claims: claims,
          role: (result.claims.role as UserRole) || UserRole.Unknown,
          status: (result.claims.status as UserStatus) || UserStatus.Disabled,
          disabled: false,
        };
        return userCore;
      })
    );
  }

  signInWithEmail(email: string, password: string) {
    return from(this.auth.signInWithEmailAndPassword(email, password)).pipe(
      switchMap(async (result) => {
        const token = await result.user.getIdToken();
        this.tokenService.setAuthToken(token);
      }),
      switchMapTo(this.getUserStream()),
      take(1)
    );
  }

  signInWithLink(link: string, email: string) {
    return from(this.auth.signInWithEmailLink(email, link)).pipe(
      switchMap(async (result) => {
        const token = await result.user.getIdToken();
        this.tokenService.setAuthToken(token);
      }),
      switchMapTo(this.getUserStream()),
      take(1)
    );
  }

  signOut() {
    return from(this.auth.signOut()).pipe(
      tap(() => {
        this.tokenService.clearAuthToken();
      })
    );
  }

  sendPasswordResetLink(email: string, url?: string) {
    return from(this.auth.sendPasswordResetEmail(email, { url }));
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.auth.user.pipe(
      take(1),
      switchMap(async (user) => {
        if (!user) {
          throw new Error('User is not signed in!');
        }

        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          currentPassword
        );
        await user.reauthenticateWithCredential(credential);
        await user.updatePassword(newPassword);

        return user.uid;
      }),
      catchError((error) => {
        if (error.code === 'auth/wrong-password') {
          error.message = 'Wrong password. Please check your current password.';
        }

        throw error;
      })
    );
  }

  refreshSession() {
    return this.auth.user.pipe(
      take(1),
      switchMap((user) => user.getIdToken(true))
    );
  }

  resetPassword(code: string, password: string) {
    return from(this.auth.confirmPasswordReset(code, password));
  }

  verifyEmail(code: string) {
    return from(this.auth.applyActionCode(code));
  }

  async setCustomTenantId(tenantId: string) {
    const sleep = (duration: number) =>
      new Promise((resolve) => setTimeout(resolve, duration));
    let attempts = 0;

    // wait a while for firebase to initialize
    while (firebase.apps.length === 0 && attempts < 100) {
      this.logger.warn(
        'Firebase is not initialized yet to set custom tenant Id. Sleeping for 100ms'
      );
      await sleep(100);
      attempts += 1;
    }

    if (firebase.apps.length === 0) {
      throw new Error('Firebase is not initialized yet!');
    }
    this.logger.debug('Setting custom tenantId');
    firebase.auth().tenantId = tenantId;
  }

  isGoogleAuthProvider() {
    return this.auth.authState.pipe(
      map((authUser) => {
        const providers = authUser.providerData.filter(
          (provider) => provider.providerId === 'google.com'
        );
        if (providers.length > 0) {
          return true;
        }

        return false;
      })
    );
  }
}
