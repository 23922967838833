export interface PrescriptionData {
  itemId: string;
  name: string;
  updatedAt: number | null;
  createdAt: number | null;
  type: PrescriptionDataType;
}

export enum PrescriptionDataType {
  TREATMENT = 'treatment',
  SPECIAL_ADVICE = 'special-advice',
}
