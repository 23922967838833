import { NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Options } from './models/options.model';
import { LoggerService } from './services/logger.service';
import { BASE_URL, LOG_LEVEL } from './services/tokens';
import { AuthTokenService } from './services/auth-token.service';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { CurrencyService } from './services/currency.service';

@NgModule({
  imports: [CommonModule],
  providers: [],
})
export class UtilsModule {
  static forRoot(options: Options) {
    return {
      ngModule: UtilsModule,
      providers: [
        // configuration values
        { provide: BASE_URL, useValue: options.baseUrl },
        { provide: LOG_LEVEL, useValue: options.logLevel },

        // interceptors
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BaseUrlInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthTokenInterceptor,
          multi: true,
        },

        AuthTokenService,
        LoggerService,
        CurrencyService,
      ],
    };
  }

  constructor(@Optional() http: HttpClient) {
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule first in your AppModule!'
      );
    }
  }
}
