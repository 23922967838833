import { Inject, Injectable, NgZone } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { LoggerService } from '@wellro/utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UNAUTHORIZED_URL } from '../services/tokens';

@Injectable({
  providedIn: 'root',
})
export class ClaimGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private ngZone: NgZone,
    private router: Router,
    @Inject(UNAUTHORIZED_URL)
    private unauthorizedUrl: string,
    private logger: LoggerService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const claims = route.data.claims as string[] | undefined;
    if (!claims) {
      throw new Error('Claims not specified!');
    }

    return this.auth.hasClaims(claims, true).pipe(
      tap((hasClaims) => {
        this.logger.debug(
          `Route ${route.url} ${hasClaims ? 'Allowed' : 'Not Allowed'}`
        );
        if (!hasClaims) {
          this.logger.warn('User is missing requested claims', {
            requested: claims,
          });

          if (this.unauthorizedUrl) {
            this.logger.debug('Redirecting to unauthorized url');
            this.ngZone.run(() => {
              this.router.navigate([this.unauthorizedUrl]);
            });
          }
        }
      })
    );
  }
}
