import { Component, EventEmitter, Input, Output } from '@angular/core';
import { bannerAnimation } from './animations';

@Component({
  selector: 'wellro-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [bannerAnimation],
})
export class BannerComponent {
  @Input() message: string;
  @Input() status: string;
  @Input() opened: boolean;

  @Output() closeBanner: EventEmitter<void> = new EventEmitter();

  get animationState() {
    return this.opened ? 'opened' : 'closed';
  }

  onClose() {
    this.closeBanner.emit();
    this.opened = false;
  }
}
