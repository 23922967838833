import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ButtonLoaderComponent } from './components/button-loader/button-loader.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { LoadingButtonDirective } from './directives/loading-button.directive';
import { SpinnerComponent } from './components/spinner/spinner.component';

const components = [
  ButtonLoaderComponent,
  SkeletonLoaderComponent,
  SpinnerComponent,
];
const directives = [LoadingButtonDirective];

@NgModule({
  imports: [CommonModule, NgxSkeletonLoaderModule],
  declarations: [...components, ...directives],
  exports: [...components, ...directives],
})
export class UiLoadersModule {}
