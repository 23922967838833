import { Component, Input } from '@angular/core';

@Component({
  selector: 'wellro-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input()
  width = '50px';

  @Input()
  strokeWidth = '2';

  @Input()
  color = '#000000';
}
