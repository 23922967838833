<quill-editor
  #editor
  [styles]="styles"
  [modules]="modules"
  [required]="required"
  [placeholder]="placeholder"
  [readOnly]="readOnly"
  [format]="'object'"
  [(ngModel)]="content"
  (onContentChanged)="onContentChanged($event)"
></quill-editor>
