<div
  class="status-banner"
  [ngClass]="status"
  [@bannerSlideToggle]="animationState"
>
  <div class="text-container">
    <div class="text">
      {{ message }}
    </div>
  </div>
  <div class="close-button-container">
    <button
      mat-icon-button
      aria-label="Banner close button"
      (click)="onClose()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
