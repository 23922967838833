// Reminder: If you update this, make sure to update libs/configurations

export enum UserStatus {
  PendingActivation = 10, // the user has not yet entered the activation code
  PendingMobileNumberVerification = 13, // the user has not yet verified the mobile number
  PendingProfileCompletion = 15, // the user has not yet completed their profile
  PendingApproval = 20, // the user is pending approval from admins
  PendingOnBoardingPayment = 25, // the user has yet to do payments
  Active = 50, // the user is active

  /**
   * This value is depreciated and will be removed
   * Please use `disabled` attribute on UserCore when making decisions based on enabled/disabled status of the user.
   */
  Disabled = -10, // the user is disabled
}
