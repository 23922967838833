import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PulseIndicatorComponent } from './components/pulse-indicator/pulse-indicator.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PulseIndicatorComponent],
  exports: [PulseIndicatorComponent],
})
export class UiAnimatedComponentsModule {}
