import { Inject, Injectable } from '@angular/core';
import { LOG_LEVEL } from './tokens';
import { LogLevel } from '../models/log-level';

@Injectable()
export class LoggerService {
  constructor(
    @Inject(LOG_LEVEL)
    private logLevel: number
  ) {}

  error(message: string, ...data): void {
    if (this.logLevel >= LogLevel.ERRORS) {
      this.log('ERROR', message, data);
    }
  }

  warn(message: string, ...data): void {
    if (this.logLevel >= LogLevel.WARNS) {
      this.log('WARN', message, data);
    }
  }

  info(message: string, ...data): void {
    if (this.logLevel >= LogLevel.INFO) {
      this.log('INFO', message, data);
    }
  }

  debug(message: string, ...data): void {
    if (this.logLevel >= LogLevel.DEBUG) {
      this.log('DEBUG', message, data);
    }
  }

  private log(type: string, message: string, data) {
    console.log(`[${type}] ${new Date().getTime()}: `, message, ...data);
  }
}
