import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'wellro-confirmation-dialog-box',
  templateUrl: './confirmation-dialog-box.component.html',
  styleUrls: ['./confirmation-dialog-box.component.scss'],
})
export class ConfirmationDialogBoxComponent {
  get title() {
    return this.data.title;
  }

  get message() {
    return this.data.message;
  }

  get consultationReadableId() {
    return this.data.consultationReadableId;
  }

  get buttons() {
    if (!this.data.buttons) {
      return [];
    }
    return this.data.buttons;
  }

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogBoxData
  ) {}

  onButtonClick(value: string) {
    this.dialogRef.close(value);
  }
}

export type ButtonType = 'ok-button' | 'cancel-button' | 'decline-button';

export interface ButtonData {
  type: ButtonType;
  text: string;
  value: string;
}

export interface ConfirmationDialogBoxData {
  title: string;
  message: string;
  buttons: ButtonData[];
  consultationReadableId?: string;
}
