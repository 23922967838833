import { Component, Input } from '@angular/core';

@Component({
  selector: 'wellro-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss'],
})
export class ButtonLoaderComponent {
  @Input() color: string;
  @Input() hide: boolean;
}
